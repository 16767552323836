"use client";
import React, { useState } from "react";
import Image from "next/image";
import { motion, AnimatePresence } from "framer-motion";

const categories = [
  { name: "Frontend", color: "from-blue-600 to-indigo-600" },
  { name: "Design", color: "from-indigo-600 to-purple-600" },
  { name: "Mobile", color: "from-purple-600 to-pink-600" },
  { name: "eCommerce", color: "from-pink-600 to-red-600" },
  { name: "CMS", color: "from-red-600 to-orange-600" },
  { name: "Database", color: "from-orange-600 to-yellow-600" },
];

const techStack = {
  Mobile: ["android", "flutter", "kotlin", "react-native", "swift"],
  eCommerce: ["magento", "prestashop", "shopify", "virtuemart", "woocommerce"],
  CMS: ["django", "elementor", "wordpress", "joomla_cms"],
  Database: ["mongodb", "mysql", "postgresql", "redis"],
  Design: ["adobe_illustrator", "adobe_xd", "figma", "photoshop", "sketch"],
  Frontend: [
    "bootstrap",
    "css3",
    "html5",
    "nextjs",
    "react",
    "sass",
    "tailwindcss",
    "typescript",
    "vue",
    "webpack",
  ],
};

const CategoryButton = ({ category, isActive, onClick }) => (
  <motion.button
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
    onClick={onClick}
    className={`relative px-6 py-3 rounded-full font-medium transition-all duration-300
      ${isActive 
        ? 'text-white shadow-lg' 
        : 'bg-white/80 backdrop-blur-sm border border-blue-100/50 hover:border-blue-200 text-gray-700 hover:text-blue-600'
      }`}
  >
    {isActive && (
      <motion.div
        layoutId="active-pill"
        className={`absolute inset-0 rounded-full bg-gradient-to-r ${category.color}`}
        transition={{ type: "spring", bounce: 0.15, duration: 0.5 }}
      />
    )}
    <span className="relative z-10">{category.name}</span>
  </motion.button>
);

const TechItem = ({ tech }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    transition={{ duration: 0.3 }}
    className="group flex flex-col items-center"
  >
    <div className="relative bg-white/80 backdrop-blur-sm p-6 rounded-2xl border border-blue-100/50 
      transition-all duration-300 hover:border-blue-200 hover:shadow-lg hover:-translate-y-1 cursor-pointer">
      <div className="w-16 h-16 relative filter grayscale group-hover:filter-none transition-all duration-300">
        <Image
          src={`/logos/${tech}.svg`}
          alt={tech}
          fill={true}
          style={{ objectFit: "contain" }}
        />
      </div>
    </div>
    <span className="mt-4 text-sm font-medium text-gray-600 group-hover:text-blue-600 transition-colors duration-300">
      {tech.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase())}
    </span>
  </motion.div>
);

const TechStackSection = () => {
  const [activeCategory, setActiveCategory] = useState("Frontend");

  return (
    <section className="py-24 relative overflow-hidden">
   

      <div className="container mx-auto px-4 max-w-6xl relative z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h2 className="text-3xl font-bold sm:text-6xl tracking-tighter mb-6">
            Our Technology Stack
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Leveraging cutting-edge technologies, we deliver software development
            services built on a foundation of both current and emerging tech
            stacks, ensuring innovative and future-ready solutions.
          </p>
        </motion.div>

        {/* Category Buttons */}
        <div className="flex flex-col sm:flex-row justify-center gap-3 mb-16">
          {categories.map((category) => (
            <CategoryButton
              key={category.name}
              category={category}
              isActive={activeCategory === category.name}
              onClick={() => setActiveCategory(category.name)}
            />
          ))}
        </div>

        {/* Tech Grid */}
        <motion.div 
          layout
          className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-6"
        >
          <AnimatePresence mode="wait">
            {techStack[activeCategory].map((tech) => (
              <TechItem key={tech} tech={tech} />
            ))}
          </AnimatePresence>
        </motion.div>
      </div>
    </section>
  );
};

export default TechStackSection;