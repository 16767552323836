"use client";
import React, { useState, useRef } from 'react';
import Image from 'next/image';
import ReactMarkdown from 'react-markdown';
import { motion, AnimatePresence } from 'framer-motion';
import { Quote } from 'lucide-react';

const TestimonialSlider = ({ 
  testimonials, 
  title = "What Our Clients Say",
  quoteIconSize = 200,
  imageSize = 100,
  className = "",
  organizationName = "Infyways Solutions",
  organizationUrl = "https://www.infyways.com"
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const constraintsRef = useRef(null);

  const handleDragEnd = (event, info) => {
    if (info.offset.x < -50) {
      setCurrentIndex((prev) => (prev + 1) % testimonials.length);
    } else if (info.offset.x > 50) {
      setCurrentIndex((prev) => (prev - 1 + testimonials.length) % testimonials.length);
    }
  };

  return (
    <section className={`py-24 relative overflow-hidden ${className}`}>
      {/* Subtle background gradient icon */}
      <div className="absolute top-0 right-0 -translate-y-1/4 translate-x-1/4 w-[600px] h-[600px] rounded-full bg-gradient-to-br from-blue-50 to-purple-50 opacity-60 blur-3xl" />
      
      <div className="max-w-6xl mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-3xl font-bold sm:text-6xl tracking-tighter mb-6 relative inline-block">
            {title}
            <motion.div 
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 0.1, scale: 1 }}
              transition={{ duration: 1 }}
              className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/3"
            >
              <Quote size={quoteIconSize} className="text-blue-400" />
            </motion.div>
          </h2>
        </motion.div>
        
        <div className="relative max-w-4xl mx-auto" ref={constraintsRef}>
          <AnimatePresence mode="wait">
            <motion.div
              key={currentIndex}
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 1.05 }}
              transition={{ type: "spring", duration: 0.5 }}
              className="flex flex-col items-center px-4"
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={0.1}
              onDragEnd={handleDragEnd}
            >
              <div className="mb-8 relative">
                <motion.div
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  className="w-[120px] h-[120px] relative"
                >
                  <Image
                    src={testimonials[currentIndex].image}
                    alt={testimonials[currentIndex].name}
                    width={120}
                    height={120}
                    className="rounded-full object-cover"
                  />
                </motion.div>
              </div>

              <div className="text-center max-w-3xl">
                <ReactMarkdown className="text-xl text-gray-600 mb-6 leading-relaxed">
                  {testimonials[currentIndex].text}
                </ReactMarkdown>
                <h3 className="text-xl font-semibold text-gray-900 mb-1">
                  {testimonials[currentIndex].name}
                </h3>
                <p className="text-blue-600">
                  {testimonials[currentIndex].position}
                </p>
              </div>
            </motion.div>
          </AnimatePresence>
        </div>

        {/* Pagination dots */}
        <div className="flex justify-center mt-12 gap-3">
          {testimonials.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className="group"
            >
              <div className={`w-2 h-2 rounded-full transition-all duration-300 
                ${index === currentIndex 
                  ? 'w-8 bg-blue-600' 
                  : 'bg-gray-300 hover:bg-gray-400'
                }`}
              />
            </button>
          ))}
        </div>
      </div>

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ 
          __html: JSON.stringify(testimonials.map(testimonial => ({
            "@context": "https://schema.org",
            "@type": "Review",
            "itemReviewed": {
              "@type": "Organization",
              "name": organizationName,
              "url": organizationUrl
            },
            "reviewBody": testimonial.text.replace(/\*\*/g, ''),
            "author": {
              "@type": "Person",
              "name": testimonial.name
            },
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "5",
              "bestRating": "5"
            }
          })))
        }}
      />
    </section>
  );
};

export default TestimonialSlider;