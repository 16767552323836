"use client";

import * as React from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { cn } from "@/lib/utils";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import {
  Code,
  Smartphone,
  Layers,
  ShoppingCart,
  Pen,
  FileText,
  Link as LinkIcon,
  Cloud,
  TrendingUp,
  Stethoscope,
  Truck,
  Landmark,
  GraduationCap,
  Home,
  Car,
  Plane,
  Briefcase,
  Globe,
  Database,
  ShieldCheck,
  Brain,
  Menu,
  X,
  MessageCircle,
} from "lucide-react";

const menuItems = {
  solutions: [
    {
      title: "Custom Software Development",
      href: "/services/custom-software/",
      description: "Tailor-made solutions to meet your unique business needs",
      icon: Code,
    },
    {
      title: "Mobile App Development",
      href: "/services/mobile-app/",
      description: "Create powerful, user-friendly apps for iOS and Android",
      icon: Smartphone,
    },
    {
      title: "Full-Stack Development",
      href: "/services/full-stack/",
      description: "End-to-end web application development services",
      icon: Layers,
    },
    {
      title: "eCommerce Development",
      href: "/services/ecommerce/",
      description: "Build robust online stores to boost your sales",
      icon: ShoppingCart,
    },
    {
      title: "UI & UX Design",
      href: "/services/ui-ux-design/",
      description: "Craft intuitive and visually appealing user interfaces",
      icon: Pen,
    },
    {
      title: "CMS Development",
      href: "/services/cms-development/",
      description: "Streamline content management with custom CMS solutions",
      icon: FileText,
    },

    {
      title: "Cloud Services",
      href: "/services/cloud/",
      description:
        "Leverage cloud technology for scalable and secure solutions",
      icon: Cloud,
    },
    {
      title: "Digital Marketing",
      href: "/services/digital-marketing/",
      description: "Boost your online presence and reach your target audience",
      icon: TrendingUp,
    },
  ],
  industries: [
    {
      title: "Retail & E-commerce",
      href: "/industries/retail/",
      description: "Innovative solutions for modern retail",
      icon: ShoppingCart,
    },
    {
      title: "Healthcare",
      href: "/industries/healthcare/",
      description: "Advancing patient care through technology",
      icon: Stethoscope,
    },
    {
      title: "Transportation & Logistics",
      href: "/industries/transportation/",
      description: "Optimizing supply chains and logistics",
      icon: Truck,
    },
    {
      title: "Finance & Fintech",
      href: "/industries/finance/",
      description: "Cutting-edge financial technology solutions",
      icon: Landmark,
    },
    {
      title: "Education Technology",
      href: "/industries/edtech/",
      description: "Empowering learning through digital platforms",
      icon: GraduationCap,
    },
    {
      title: "Real Estate",
      href: "/industries/real-estate/",
      description: "Streamlining property management and transactions",
      icon: Home,
    },
    {
      title: "Automotive",
      href: "/industries/automotive/",
      description: "Driving innovation in the automotive sector",
      icon: Car,
    },
    {
      title: "Travel & Hospitality",
      href: "/industries/travel/",
      description: "Enhancing guest experiences through technology",
      icon: Plane,
    },
    {
      title: "Government & Public Sector",
      href: "/industries/government/",
      description: "Modernizing public services with digital solutions",
      icon: Briefcase,
    },
  ],
  technologies: [
    {
      title: "Front-End Technologies",
      href: "/tech/frontend/",
      description: "Cutting-edge web development frameworks",
      icon: Globe,
    },

    {
      title: "Backend Technologies",
      href: "/tech/backend/",
      description: "Robust server-side programming languages",
      icon: Layers,
    },

    {
      title: "Mobile Technologies",
      href: "/tech/mobile/",
      description: "Native and cross-platform mobile development",
      icon: Smartphone,
    },
    {
      title: "Cloud Platforms",
      href: "/tech/cloud/",
      description: "Leveraging cloud infrastructure for scalability",
      icon: Cloud,
    },
    {
      title: "AI & Machine Learning",
      href: "/tech/ai-ml/",
      description: "Intelligent solutions powered by AI",
      icon: Brain,
    },

    {
      title: "Security Technologies",
      href: "/tech/security/",
      description: "Advanced cybersecurity measures",
      icon: ShieldCheck,
    },
  ],
};

const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a"> & {
    icon: React.ElementType;
    title: string;
    description: string;
    isActive: boolean;
  }
>(({ className, title, description, icon: Icon, isActive, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            "flex items-center space-x-4 rounded-md p-3 no-underline outline-none transition-colors",
            "hover:bg-slate-100 hover:text-blue-600",
            isActive && "bg-slate-100 text-blue-600",
            className
          )}
          {...props}
        >
          <div className="flex bg-slate-50 p-4 rounded-2xl">
            <Icon
              size={30}
              strokeWidth={1.5}
              color={isActive ? "#1d4ed8" : "#1818EA"}
            />
          </div>
          <div>
            <div className="text-sm font-medium leading-none">{title}</div>
            <p className="line-clamp-2 text-sm leading-snug text-muted-foreground mt-1">
              {description}
            </p>
          </div>
        </a>
      </NavigationMenuLink>
    </li>
  );
});

ListItem.displayName = "ListItem";
export default function Nav() {
  const [isOpen, setIsOpen] = React.useState(false);
  const pathname = usePathname();

  const toggleMenu = () => setIsOpen(!isOpen);

  const isActive = (href) => {
    return pathname.startsWith(href);
  };

  return (
    <>
      {/* Desktop menu */}
      <div className="hidden lg:block">
        <NavigationMenu>
          <NavigationMenuList>
            {Object.entries(menuItems).map(([key, items]) => (
              <NavigationMenuItem key={key}>
                <NavigationMenuTrigger
                  className={cn(
                    " hover:text-blue-600",
                    items.some((item) => isActive(item.href)) &&
                      " text-blue-600"
                  )}
                >
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </NavigationMenuTrigger>
                <NavigationMenuContent>
                  <ul className="grid w-[800px] gap-3 p-4 md:grid-cols-2">
                    {items.map((item) => (
                      <ListItem
                        key={item.title}
                        {...item}
                        isActive={isActive(item.href)}
                      />
                    ))}
                  </ul>
                </NavigationMenuContent>
              </NavigationMenuItem>
            ))}
            {["About", "Blog", "Tools"].map((item) => (
              <NavigationMenuItem key={item}>
                <Link href={`/${item.toLowerCase()}`} legacyBehavior passHref>
                  <NavigationMenuLink
                    className={cn(
                      navigationMenuTriggerStyle(),
                      " hover:text-blue-600",
                      isActive(`/${item.toLowerCase()}`) && " text-blue-600"
                    )}
                  >
                    {item}
                  </NavigationMenuLink>
                </Link>
              </NavigationMenuItem>
            ))}
          </NavigationMenuList>
        </NavigationMenu>
      </div>
      {/* Mobile top bar */}
      <div className="lg:hidden absolute top-0 right-0 z-50 p-4 flex items-center">
        <button
          onClick={toggleMenu}
          className="p-2"
          aria-label={isOpen ? "Close Menu" : "Open Menu"} // Dynamically set the accessible name
        >
          {isOpen ? (
            <X size={24} color="rgb(25 28 237)" />
          ) : (
            <Menu size={24} color="rgb(25 28 237)" />
          )}
        </button>
      </div>

      {/* Full-screen mobile menu */}
      {isOpen && (
        <div className="lg:hidden fixed inset-0 bg-white z-40 overflow-y-auto pt-2">
          <div className="p-4">
            {Object.entries(menuItems).map(([key, items]) => (
              <div key={key} className="mb-6">
                <h2 className="text-xl font-bold mb-2 tracking-tight">
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </h2>
                {items.map((item) => (
                  <Link
                    key={item.title}
                    href={item.href}
                    className={cn(
                      "flex items-center space-x-4 rounded-md p-2 no-underline outline-none transition-colors",
                      "hover:bg-blue-100 hover:text-blue-700",
                      isActive(item.href) && "bg-blue-10 text-blue-700"
                    )}
                    onClick={toggleMenu}
                  >
                    <div className="font-medium">{item.title}</div>
                  </Link>
                ))}
              </div>
            ))}
            {["Process", "About", "Blog", "Contact"].map((item) => (
              <Link
                key={item}
                href={`/${item.toLowerCase()}`}
                className={cn(
                  "block py-3 font-medium border-b border-gray-100",
                  "hover:bg-blue-100 hover:text-blue-700",
                  isActive(`/${item.toLowerCase()}`) &&
                    "bg-blue-100 text-blue-700"
                )}
                onClick={toggleMenu}
              >
                {item}
              </Link>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
