"use client";
import React from "react";
import { ArrowRight, Code, Users, Sparkles } from "lucide-react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import Image from "next/image";

const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="relative group">
    <div className="bg-white/80 backdrop-blur-lg p-6 rounded-2xl border border-blue-100/50 
      transition-all duration-300 hover:border-blue-200 hover:shadow-lg hover:-translate-y-1">
      <div className="flex items-center mb-4">
        <div className="bg-blue-50 w-10 h-10 rounded-xl flex items-center justify-center
          transition-transform duration-300 group-hover:scale-110 mr-4">
          <Icon className="w-5 h-5 text-blue-600" />
        </div>
        <h3 className="text-lg font-semibold transition-colors duration-300 group-hover:text-blue-600">
          {title}
        </h3>
      </div>
      <p className="text-gray-600 text-sm leading-relaxed">{description}</p>
    </div>
  </div>
);

const CTASection = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { 
        type: "spring",
        duration: 0.8,
      },
    },
  };

  return (
    <section className="py-24 relative overflow-hidden">
      <motion.div
        ref={ref}
        variants={containerVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        className="max-w-6xl mx-auto px-4 relative z-10"
      >
        <div className="flex flex-col lg:flex-row items-center gap-12">
          <div className="lg:w-1/2">
            <motion.div variants={itemVariants} className="inline-flex items-center gap-2 bg-white/80 backdrop-blur-sm px-4 py-2 rounded-full border border-blue-100/50 mb-6">
              <Sparkles className="w-4 h-4 text-blue-600" />
              <span className="text-sm font-medium text-gray-600">
                More than 500+ Satisfied Customers
              </span>
            </motion.div>

            <motion.h2
              variants={itemVariants}
              className="text-4xl sm:text-5xl font-bold tracking-tighter mb-6"
            >
              Supercharge Your Business Growth with{" "}
              <span className="bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
                Tailored Digital Solutions!
              </span>
            </motion.h2>

            <motion.p 
              variants={itemVariants}
              className="text-lg text-gray-600 mb-8"
            >
              Empower your brand with cutting-edge technology and innovative strategies. 
              From website development to digital marketing, we offer solutions that drive growth, 
              enhance customer engagement, and maximize your success.
            </motion.p>

            <motion.div variants={itemVariants} className="mb-12">
              <a
                href="/contact/"
                className="inline-flex items-center gap-2 px-8 py-4 rounded-full font-medium 
                  bg-gradient-to-r from-blue-600 to-purple-600 text-white
                  hover:shadow-lg hover:shadow-blue-500/25 transition-all duration-300 hover:-translate-y-1"
              >
                Request a Quote
                <ArrowRight className="w-5 h-5" />
              </a>
            </motion.div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <motion.div variants={itemVariants}>
                <FeatureCard
                  icon={Code}
                  title="Custom Solutions"
                  description="Tailored software and digital platforms that fit your business needs perfectly."
                />
              </motion.div>
              <motion.div variants={itemVariants}>
                <FeatureCard
                  icon={Users}
                  title="Dedicated Experts"
                  description="Work with a team of skilled professionals committed to driving your success."
                />
              </motion.div>
            </div>
          </div>

          <motion.div
            variants={itemVariants}
            className="lg:w-1/2 relative"
          >
            <div className="relative bg-white/80 backdrop-blur-lg rounded-3xl border border-blue-100/50 p-4 transition-all duration-300 hover:shadow-xl">
              <Image
                width={500}
                height={500}
                src="/images/cta-image.jpg"
                alt="Infyways Call to Action"
                className="w-full h-auto rounded-2xl"
              />
            </div>
          </motion.div>
        </div>
      </motion.div>
    </section>
  );
};

export default CTASection;