"use client";
import React, { useRef } from "react";
import Image from "next/image";
import { motion, useScroll, useTransform } from "framer-motion";
import { ArrowRight, Clock, CheckCircle, Users, Globe } from "lucide-react";
import Link from "next/link";

const FloatingCard = ({ icon: Icon, value, label, position, delay }) => {
  const { scrollYProgress } = useScroll();
  const opacity = useTransform(scrollYProgress, [0, 0.2], [1, 0]);
  const y = useTransform(scrollYProgress, [0, 0.3], [0, 100]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay }}
      style={{ opacity, y }}
      className={`hidden lg:block absolute bg-white border border-blue-100/50 rounded-3xl  p-6 w-64 transform transition-all duration-300 hover:shadow-lg hover:-translate-y-1 ${position}`}
    >
      <div className="flex flex-col-reverse lg:flex-row items-center gap-4 text-center lg:text-left">
        <div className="h-12 w-12 rounded-2xl bg-blue-50 flex items-center justify-center">
          <Icon className="w-6 h-6 text-blue-600" />
        </div>
        <div>
          <h4 className="font-bold text-gray-800">{value}</h4>
          <p className="text-sm text-gray-600">{label}</p>
        </div>
      </div>
    </motion.div>
  );
};

const CreativeHero = () => {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start start", "end start"],
  });

  const y1 = useTransform(scrollYProgress, [0, 1], [0, -100]);
  const y2 = useTransform(scrollYProgress, [0, 1], [0, 100]);
  const opacity = useTransform(scrollYProgress, [0, 0.5], [1, 0]);
  const blurAmount = useTransform(scrollYProgress, [0, 0.5], [0, 5]);

  const statsData = [
    { icon: Clock, value: "14+", label: "Years Experience" },
    { icon: CheckCircle, value: "1500+", label: "Projects Delivered" },
    { icon: Users, value: "500+", label: "Clients Worldwide" },
    { icon: Globe, value: "50+", label: "Countries Reached" },
  ];

  const floatingPositions = [
    "top-16 -left-12 rotate-[-5deg]",
    "top-1/4 -right-24 rotate-[3deg]",
    "bottom-32 -left-28 rotate-[2deg]",
    "-bottom-10 right-[-6rem] rotate-[-3deg]",
  ];

  return (
    <div
      ref={containerRef}
      className="relative min-h-screen overflow-hidden "
    >
      {/* Animated Background */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute w-[800px] h-[800px] top-20 -right-40 bg-blue-600/5 rounded-full blur-3xl animate-pulse" />
       
      </div>

      {/* Main Content */}
      <div className="relative container mx-auto px-4 pt-24">
        <div className="grid lg:grid-cols-12 gap-12 items-center">
          {/* Left Column */}
          <motion.div
            className="lg:col-span-6 z-10"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
          >
            <span className="inline-block bg-blue-600/10 text-blue-600 px-4 py-2 rounded-full text-sm font-medium mb-6">
              Next-Gen Technology Solutions
            </span>

            <motion.h1
              className="text-5xl md:text-6xl lg:text-7xl font-bold leading-tight mb-6"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              Transform Your
              <motion.span
                className="block mt-2 text-blue-600"
                animate={{
                  opacity: [1, 0.8, 1],
                }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
              >
                Digital Future
              </motion.span>
            </motion.h1>

            <motion.p
              className=" text-gray-600 mb-8"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
            >
              Empowering businesses with cutting-edge solutions that drive
              growth and innovation in the digital age.
            </motion.p>

            {/* CTA Button */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6 }}
              className="mb-12"
            >
              <Link
                href="/contact"
                className="group inline-flex px-8 py-4 bg-blue-600 text-white rounded-full font-medium transition-all duration-300 hover:bg-blue-700 hover:shadow-lg hover:-translate-y-1"
              >
                <span className="flex items-center gap-2">
                  Get Started Now
                  <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
                </span>
              </Link>
            </motion.div>

            {/* Mobile Stats Grid */}
            <div className="grid grid-cols-2 gap-4 lg:hidden mt-8">
              {statsData.map((stat, index) => (
                <motion.div
                  key={stat.label}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.8 + index * 0.1 }}
                  className="bg-white/90 backdrop-blur-lg rounded-xl p-4 shadow-lg border border-blue-100"
                >
                  <div className="flex flex-col items-center gap-3 text-center">
                    <div className="h-10 w-10 rounded-full bg-blue-600 flex items-center justify-center">
                      <stat.icon className="w-5 h-5 text-white" />
                    </div>
                    <div>
                      <h4 className="font-bold text-gray-800">{stat.value}</h4>
                      <p className="text-sm text-gray-600">{stat.label}</p>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>

          {/* Right Column */}
          <motion.div
            className="lg:col-span-6 relative"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <div className="relative aspect-square">
              {/* Main Image */}
              <motion.div
                className="absolute inset-0 rounded-2xl overflow-hidden shadow-2xl"
                style={{ y: y1 }}
              >
                <div className="relative w-full h-full">
                  <Image
                    src="/images/digital-innovation.jpg"
                    alt="Digital Innovation"
                    fill
                    priority
                    className="object-cover"
                  />
                  <motion.div
                    className="absolute inset-0 bg-blue-900/10"
                    style={{
                      filter: `blur(${blurAmount.get()}px)`,
                    }}
                  />
                </div>
              </motion.div>

              {/* Floating Stats Cards */}
              {statsData.map((stat, index) => (
                <FloatingCard
                  key={stat.label}
                  icon={stat.icon}
                  value={stat.value}
                  label={stat.label}
                  position={floatingPositions[index]}
                  delay={0.8 + index * 0.2}
                />
              ))}

              {/* Decorative Border */}
              <motion.div
                className="absolute -z-10 w-full h-full rounded-2xl border-2 border-blue-200 top-8 left-8"
                animate={{
                  scale: [1, 1.02, 1],
                  rotate: [0, 1, 0],
                }}
                transition={{
                  duration: 4,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
              />
            </div>
          </motion.div>
        </div>
      </div>

      {/* Scroll Indicator */}
      <motion.div
        style={{ opacity }}
        className="absolute bottom-8 left-1/2 -translate-x-1/2"
      >
        <motion.div
          animate={{
            y: [0, 10, 0],
          }}
          transition={{
            duration: 1.5,
            repeat: Infinity,
            ease: "easeInOut",
          }}
          className="text-blue-600 text-2xl"
        >
          ↓
        </motion.div>
      </motion.div>
    </div>
  );
};

export default CreativeHero;
