"use client";
import React, { useRef } from "react";
import Link from "next/link";
import { motion, useScroll, useTransform } from "framer-motion";
import {
  Code,
  Smartphone,
  Layers,
  ShoppingCart,
  Pen,
  FileText,
  Cloud,
  TrendingUp,
  ArrowUpRight
} from "lucide-react";

const services = [
  {
    title: "Custom Software",
    href: "/services/custom-software/",
    icon: Code,
    description: "Build tailored software solutions that perfectly align with your business needs and objectives.",
  },
  {
    title: "Mobile Apps",
    href: "/services/mobile-app/",
    icon: Smartphone,
    description: "Create engaging mobile experiences for iOS and Android platforms with cutting-edge technology.",
  },
  {
    title: "Full-Stack Development",
    href: "/services/full-stack/",
    icon: Layers,
    description: "End-to-end development services covering both frontend and backend requirements.",
  },
  {
    title: "eCommerce",
    href: "/services/ecommerce/",
    icon: ShoppingCart,
    description: "Build scalable online stores with seamless shopping experiences and secure payments.",
  },
  {
    title: "UI & UX Design",
    href: "/services/ui-ux-design/",
    icon: Pen,
    description: "Create intuitive and visually appealing interfaces that delight your users.",
  },
  {
    title: "CMS Development",
    href: "/services/cms-development/",
    icon: FileText,
    description: "Develop custom content management systems that make content updates effortless.",
  },
  {
    title: "Cloud Services",
    href: "/services/cloud/",
    icon: Cloud,
    description: "Implement robust cloud solutions for improved scalability and performance.",
  },
  {
    title: "Digital Marketing",
    href: "/services/digital-marketing/",
    icon: TrendingUp,
    description: "Drive growth with data-driven digital marketing strategies and campaigns.",
  },
];

const ServiceCard = ({ service, index }) => {
  const cardRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: cardRef,
    offset: ["start end", "end start"]
  });

  const y = useTransform(scrollYProgress, [0, 1], [100, -50]);
  const opacity = useTransform(scrollYProgress, [0, 0.2, 0.9, 1], [0, 1, 1, 0]);

  return (
    <motion.div
      ref={cardRef}
      style={{ opacity, y }}
      className="relative"
    >
      <Link href={service.href} className="block group">
        <div className="relative h-full bg-white/80 backdrop-blur-lg p-8 rounded-3xl border border-blue-100/50
          transition-all duration-500 hover:bg-blue-50/50 hover:border-blue-200 hover:shadow-lg">
          
          {/* Icon and Arrow Container */}
          <div className="flex justify-between items-start mb-6">
            <div className="w-14 h-14 bg-blue-50 rounded-2xl flex items-center justify-center
              transition-all duration-500 group-hover:scale-110 group-hover:bg-blue-600">
              <service.icon className="w-7 h-7 text-blue-600 transition-colors duration-500 
                group-hover:text-white" />
            </div>
            <ArrowUpRight className="w-6 h-6 text-blue-600 opacity-0 transform translate-x-2
              transition-all duration-500 group-hover:opacity-100 group-hover:translate-x-0" />
          </div>

          {/* Content */}
          <div>
            <h3 className="text-2xl font-bold mb-3 text-gray-900 transition-colors duration-300
              group-hover:text-blue-600">
              {service.title}
            </h3>
            <p className="text-gray-600 leading-relaxed transition-colors duration-300
              group-hover:text-gray-700">
              {service.description}
            </p>
          </div>
        </div>
      </Link>
    </motion.div>
  );
};

const ServicesShowcase = () => {
  return (
    <section className="py-32 relative overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="text-center mb-24"
        >
          <span className="inline-block text-blue-600 bg-blue-50 px-4 py-2 rounded-full 
            text-sm font-medium mb-6">
            Our Services
          </span>
          
          <h2 className="text-5xl md:text-7xl font-bold tracking-tight mb-8">
            Our Expertise at
            <span className="block text-blue-600 mt-2">Your Service</span>
          </h2>
          
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Explore our range of innovative solutions designed to elevate your
            business in the digital landscape.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <ServiceCard 
              key={service.title} 
              service={service}
              index={index}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesShowcase;